import { clearingApi } from "../config/apiConfig";

export const getBankAccountDetails = async (id) => {
  try {
    const response = await clearingApi.get(`payout-details?station_id=${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getBankAccountsList = async () => {
  try {
    const response = await clearingApi.get(`payout-details/all`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const addBankAccountDetails = async (
  id,
  name,
  iban,
  bic = "",
  email
) => {
  try {
    const response = await clearingApi.put(`payout-details?station_id=${id}`, {
      station_id: id,
      account_holder_name: name,
      iban: iban,
      bic: bic,
      email: email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
