import axios from "axios";
import { getToken } from "../storage/AuthStorage";

const createApiClient = (baseURL) => {
  const apiClient = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  apiClient.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return apiClient;
};

const locationApi = createApiClient(process.env.REACT_APP_LOCATION_URL);
const csCommunicationApi = createApiClient(
  process.env.REACT_APP_CSCOMMUNICATION
);
const accountApi = createApiClient(process.env.REACT_APP_ACCOUNT_BASE_URL);
const clearingApi = createApiClient(process.env.REACT_APP_CLEARING_BASE_URL);

export {
  createApiClient,
  locationApi,
  csCommunicationApi,
  accountApi,
  clearingApi,
};
